import { Observable } from 'lib0/observable';

// store a reference to window.fetch in case it gets overwritten externally. This could
// be abused to intercept the auth token
const fetch = typeof window !== 'undefined' ? window.fetch : global.fetch;

// auth data stored here is inaccessible to anything outside this module.
let authToken = null;

// main fetch method
const runFetch = async (fetchInstance, url, config) => {

	config.headers = config.headers || {};

	if(!config.headers.hasOwnProperty('Accept')) {
		config.headers['Accept'] = 'application/json, text/plain, */*';
	}

	// convert withCredentials option to credentials: 'include'
	if(config.withCredentials && !config.hasOwnProperty('credentials')) {
		delete config.withCredentials;
		config.credentials = 'include';
	}

	// set auth headers if we have a token and Authorization headers
	// are not already configured
	if(
		fetchInstance.hasAuthorization() 
		&& !config.headers.hasOwnProperty('Authorization')
	) {
		config.headers['Authorization'] = 'Bearer ' + authToken;
	}

	// filter out headers explicitly set to undefined or null
	for (const [key, value] of Object.entries(config.headers)) {
		if(value === undefined || value === null) {
			delete config.headers[key];
		}
	}

	let result;

	try {
		result = await fetch(url, config);
	} catch (error) {
		// TypeError: Failed to fetch
		fetchInstance.emit('error', [error, config]);
		throw error;
	}

	if(config.responseType === "blob") {
		
		result.data = await result.blob();

	} else {

		// Assume return data is JSON for now.
		const text = await result.text();

		// store the data on the result
		try {
			// Assume the result is JSON
			result.data = JSON.parse(text);
		} catch(e) {
			// fall back to text if the response can't be parsed as JSON
			result.data = text;
		}

	}

	if(result.status < 200 || result.status >= 300) {
		fetchInstance.emit('rejected', [result, config]);
		throw result;
	}

	// return the response
	return result;

}

const setRequestBody = (config, data) => {

	if(!data) {
		return config;
	}

	config.headers = config.headers || {};

	if(typeof data === "object") {

		if(data instanceof Blob) {
			
			// send blobs raw
			config.body = data;

			// set correct Content-Type if not already set
			if(!config.headers.hasOwnProperty('Content-Type')) {
				config.headers['Content-Type'] = "application/octet-stream";
			}

		} else {

			// serialize any other object
			config.body = JSON.stringify(data);

			// set correct Content-Type if not already set
			if(!config.headers.hasOwnProperty('Content-Type')) {
				config.headers['Content-Type'] = "application/json";
			}

		}

	} else {
		
		config.body = data;

	}

	return config;


}

// public facing class
class CargoFetcher extends Observable {

	setAuthorization = token => {
		authToken = token;
	}

	hasAuthorization = () => {
		return typeof authToken === "string" && authToken.length > 0;
	}

	get = (url, config = {}) => {

		config.method = "GET";

		return runFetch(this, url, config);

	}

	post = (url, data, config = {}) => {

		config.method = "POST";
		setRequestBody(config, data);

		return runFetch(this, url, config);

	}

	put = (url, data, config = {}) => {

		config.method = "PUT";
		setRequestBody(config, data);

		return runFetch(this, url, config);

	}

	patch = (url, data, config = {}) => {

		config.method = "PATCH";
		setRequestBody(config, data);

		return runFetch(this, url, config);

	}

	delete = (url, data, config = {}) => {

		config.method = "DELETE";
		setRequestBody(config, data);

		return runFetch(this, url, config);

	}

}

// default instance
export default new CargoFetcher();